import React, { useEffect, useRef, useState } from "react";
import { useAsyncMemo } from "@spolive-team/hooks";
import { fetchTeamMaster } from "assets/js/firebase";
import useGameData from "hooks/use-gameData";
import { TeamMaster } from "models/teamMaster";
import { createContext, useContext } from "react";
import { Points } from "models/game";
import { calculateTimelineData } from "assets/js/calculateTimelineData";
import { FirebaseContext } from "contexts";
import { PeriodGamePoint } from "models/timeline";

export type GameContextValue = {
  sportsId: string;
  leagueId: string;
  gameId: string;
  locale: string;
  homePoint: number | undefined;
  awayPoint: number | undefined;
  homeTeamMaster?: TeamMaster;
  awayTeamMaster?: TeamMaster;
  firstHalf?: Points;
  lastHalf?: Points;
} & ReturnType<typeof useGameData>;

export const GameContext = createContext<GameContextValue>({
  homePoint: undefined,
  awayPoint: undefined,
  sportsId: "",
  leagueId: "",
  gameId: "",
  locale: "ja",
  loading: true,
  error: undefined,
  gameData: undefined,
  infoDateDay: "",
  infoDateDayOfWeek: "",
  infoDateTime: "",
  infoDateUtc: "",
  latestPoints: { home: 0, away: 0 },
  latestGamePoints: undefined,
  placeImageUrl: "",
  specifiedPlay: undefined,
  timelineData: undefined,
  leagueData: undefined
});

export const GameContextProvider = ({
  children,
  ...value
}: {
  children: React.ReactNode;
} & {
  sportsId: string;
  leagueId: string;
  gameId: string;
  locale: string;
  timelineId?: string;
  homePoint?: number;
  awayPoint?: number;
}) => {
  const { sportsId, leagueId, gameId, locale, homePoint, awayPoint, timelineId } = value;
  const gameData = useGameData({
    sportsId,
    leagueId,
    gameId,
    timelineId
  });

  const [timelineData, setTimelineData] = useState<{}>();
  // TODO: TimelinePlayのネストを解消したら型を付ける
  const [specifiedPlay, setSpecifiedPlay] = useState<any>();

  const [latestPoints, setLatestPoints] = useState({ home: 0, away: 0 });
  const [latestGamePoints, setLatestGamePoints] = useState<PeriodGamePoint | undefined>();

  const homeTeamMaster = useAsyncMemo(async () => {
    if (!gameData.gameData?.homeTeam?.masterTeamId) return;
    const teamMaster = fetchTeamMaster(sportsId, gameData?.gameData?.homeTeam?.masterTeamId);
    return teamMaster;
  }, [gameData.gameData?.homeTeam?.masterTeamId]);

  const awayTeamMaster = useAsyncMemo(async () => {
    if (!gameData.gameData?.awayTeam?.masterTeamId) return;
    const teamMaster = fetchTeamMaster(sportsId, gameData?.gameData?.awayTeam?.masterTeamId);
    return teamMaster;
  }, [gameData.gameData?.awayTeam?.masterTeamId]);

  const firebaseRef = useRef(useContext(FirebaseContext));
  useEffect(() => {
    (async () => {
      const { realtimeDb } = firebaseRef.current;
      if (!realtimeDb) throw new Error("Realtime DB is not initialized");

      await new Promise<void>(resolve => {
        realtimeDb
          .ref(`timeline/${sportsId}/${leagueId}/${gameId}`)
          .on("value", timelineSnapShot => {
            const { timelineData, latestPoints, latestGamePoints, specifiedPlay } =
              calculateTimelineData(timelineSnapShot.val(), sportsId, timelineId);
            if (timelineData) {
              setTimelineData(timelineData);
            }
            if (specifiedPlay) {
              setSpecifiedPlay(specifiedPlay);
            }
            setLatestPoints(latestPoints);
            setLatestGamePoints(latestGamePoints);
            resolve();
          });
      });
    })();
  }, [sportsId, leagueId, gameId, timelineId]);
  return (
    <GameContext.Provider
      value={{
        sportsId,
        leagueId,
        gameId,
        locale,
        homePoint,
        awayPoint,
        homeTeamMaster,
        awayTeamMaster,
        ...gameData,
        timelineData,
        latestPoints,
        latestGamePoints,
        specifiedPlay
      }}
    >
      {children}
    </GameContext.Provider>
  );
};

export const useGameContext = () => {
  const context = useContext(GameContext);
  const { sportsId, leagueId, gameId } = context;

  if (!sportsId || !leagueId || !gameId) {
    throw new Error("GameContext is not provided");
  }

  return context;
};
