import React, { FC, ReactElement, useEffect, useMemo } from "react";
import { last } from "lodash";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import styled from "styled-components";
import img_logo from "assets/images/logo.png";
import img_crown from "assets/images/crown.png";
import img_confetti from "assets/images/confetti.png";
import img_bgFrame from "assets/images/bg_frame.png";

import { teamImgIsCircle, isShowLastPeriodGamePoint } from "assets/sportsData";
import { GameStatusLabel } from "components/atoms/gameStatusLabel";
import { GamePeriodLabel } from "components/atoms/GamePeriodLabel";

import { Point } from "models/timeline";

import { splashBg } from "assets/js/sportsImages";
import { convertGameStatus } from "assets/js/convertGameStatus";
import { getTeamNameShort } from "assets/js/textConvert";
import setHtmlMeta from "assets/js/setHtmlMeta";
import { TeamImage } from "components/atoms/TeamImage";
import { useGameContext } from "libs/gameContext";

type Props = {
  sportsId: string;
  leagueId: string;
  gameId: string;
  locale: "ja" | "en";
  homePoint: string | null;
  awayPoint: string | null;
};

const viewportSize = 1200;

export const HomeAwaySportsCard: FC<Props> = ({
  sportsId,
  leagueId,
  gameId,
  locale,
  homePoint,
  awayPoint
}): ReactElement | null => {
  const isJa = locale === "ja";
  const {
    gameData,
    loading,
    error,
    latestPoints,
    latestGamePoints,
    placeImageUrl,
    leagueData,
    homeTeamMaster,
    awayTeamMaster
  } = useGameContext();
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.tz.setDefault("Asia/Tokyo");

  const gameStatus = convertGameStatus(isJa, gameData);
  const isShowCrown = gameStatus.status === "finished" && latestPoints.home !== latestPoints.away;
  const isShowCrownIsHome = latestPoints.home > latestPoints.away;
  const leagueText = (!isJa && gameData?.info_leagueText_en) || gameData?.info_leagueText;
  const placeName = (!isJa && gameData?.info_placeName_en) || gameData?.info_placeName;
  const gameDate = gameData?.info_datetime
    ? dayjs(gameData.info_datetime.toDate()).tz().format("YYYY.M.D")
    : "";
  const gameTime = gameData?.info_datetime
    ? dayjs(gameData.info_datetime.toDate()).tz().format("H:mm〜")
    : "";
  const gameDayOfWeek = gameData?.info_datetime
    ? dayjs(gameData.info_datetime.toDate()).tz().format("ddd")
    : "";
  const isValidPoint = (point: any): boolean => {
    const num = parseInt(point, 10);
    return point != null && typeof num === "number" && num >= 0;
  };

  //テニスなどの場合にはピリオドごとの点数を表示
  let displayHomePoint: number | string | null | undefined = latestPoints?.home;
  let displayAwayPoint: number | string | null | undefined = latestPoints?.away;
  if (isValidPoint(homePoint) && isValidPoint(awayPoint)) {
    displayHomePoint = homePoint;
    displayAwayPoint = awayPoint;
  }
  if (isShowLastPeriodGamePoint(sportsId) && latestGamePoints && gameData?.periodList) {
    const lastPeriod = last(gameData?.periodList);
    if (lastPeriod?.id && latestGamePoints?.[lastPeriod.id]) {
      const latestGamePointSet = latestGamePoints[lastPeriod.id] as Point;
      displayHomePoint = latestGamePointSet.home;
      displayAwayPoint = latestGamePointSet.away;
    }
  }
  useEffect(() => {
    setHtmlMeta({ viewport: "width=" + viewportSize });
  }, []);

  const homeTeamNameJa = useMemo(() => {
    return (
      gameData?.homeTeam?.companyName ||
      gameData?.homeTeam?.name ||
      gameData?.team_homeName_ifEmptyTeamId ||
      ""
    );
  }, [
    gameData?.homeTeam?.companyName,
    gameData?.homeTeam?.name,
    gameData?.team_homeName_ifEmptyTeamId
  ]);
  const homeTeamNameEn = useMemo(() => {
    return gameData?.homeTeam?.companyName_en || gameData?.homeTeam?.name_en || homeTeamNameJa;
  }, [gameData?.homeTeam, homeTeamNameJa]);
  const awayTeamNameJa = useMemo(() => {
    return (
      gameData?.awayTeam?.companyName ||
      gameData?.awayTeam?.name ||
      gameData?.team_awayName_ifEmptyTeamId ||
      ""
    );
  }, [
    gameData?.awayTeam?.companyName,
    gameData?.awayTeam?.name,
    gameData?.team_awayName_ifEmptyTeamId
  ]);
  const awayTeamNameEn = useMemo(() => {
    return gameData?.awayTeam?.companyName_en || gameData?.awayTeam?.name_en || awayTeamNameJa;
  }, [gameData?.awayTeam, awayTeamNameJa]);

  if (loading) {
    // preview読み込み中に期待外の画面を表示させない
    return null;
  }
  if (!sportsId || !splashBg[sportsId] || error) {
    return <Wrapper bgimg={"/ogimage.png"} hasGameDate={false}></Wrapper>;
  }
  return (
    <Wrapper
      bgimg={placeImageUrl || splashBg[sportsId]}
      hasGameDate={gameData?.info_datetime ? true : false}
      className="p-gameSplash"
    >
      <div className="p-gameSplash_bgFrame" />
      {isShowCrown && (
        <img
          className="p-gameSplash_confetti"
          alt="confetti"
          data-ishomewin={isShowCrownIsHome}
          src={img_confetti}
        />
      )}
      <div className="p-gameSplash_labelArea">
        <GameStatusLabel gameStatus={gameStatus} />
        <GamePeriodLabel gameData={gameData} sportsId={sportsId} isJa={isJa} />
      </div>
      <div className="p-gameSplash_teamPointArea">
        <div className="p-gameSplash_teamArea">
          <div className="p-gameSplash_teamImgWrapper" data-iscircle={teamImgIsCircle(sportsId)}>
            <TeamImage
              isFit={leagueData?.isTeamImageFit}
              logoUrl={gameData?.homeTeam?.logoUrl}
              diameter={180}
              name={homeTeamNameJa}
              name_en={homeTeamNameEn}
              sportsId={sportsId}
              color={homeTeamMaster?.color}
            />
          </div>

          <span className="p-gameSplash_teamName">{getTeamNameShort(isJa, true, gameData)}</span>
        </div>
        {gameStatus.isShowPoint ? (
          (() => {
            const has3digit =
              String(displayHomePoint).length > 2 || String(displayAwayPoint).length > 2;
            return (
              <div className="p-gameSplash_pointArea">
                <span className="p-gameSplash_pointNumber" data-threedigit={has3digit}>
                  {displayHomePoint}
                </span>
                <span className="p-gameSplash_pointHyphen" data-threedigit={has3digit}>
                  -
                </span>
                <span className="p-gameSplash_pointNumber" data-threedigit={has3digit}>
                  {displayAwayPoint}
                </span>
              </div>
            );
          })()
        ) : (
          <p className="p-gameSplash_pointVs">VS</p>
        )}
        <div className="p-gameSplash_teamArea">
          <div className="p-gameSplash_teamImgWrapper" data-iscircle={teamImgIsCircle(sportsId)}>
            <TeamImage
              isFit={leagueData?.isTeamImageFit}
              logoUrl={gameData?.awayTeam?.logoUrl}
              diameter={180}
              name={awayTeamNameJa}
              name_en={awayTeamNameEn}
              sportsId={sportsId}
              color={awayTeamMaster?.color}
            />
          </div>
          <span className="p-gameSplash_teamName">{getTeamNameShort(isJa, false, gameData)}</span>
        </div>
        {isShowCrown && (
          <img
            className="p-gameSplash_crown"
            alt="srown"
            data-ishomewin={isShowCrownIsHome}
            src={img_crown}
          />
        )}
      </div>
      <p className="p-gameSplash_leagueText">
        {gameData && <span>{leagueText}</span>}
        <br />
        {gameData && <span>{placeName}</span>}
      </p>
      <div className="p-gameSplash_leagueDateTime">
        <span className="p-gameSplash_leagueDate">{gameDate && <span>{gameDate}</span>}</span>
        <span className="p-gameSplash_leagueDayOfWeek">
          {gameDayOfWeek && <span> ({gameDayOfWeek}) </span>}
        </span>
        <span className="p-gameSplash_leagueTime">{gameDate && <span>{gameTime}</span>}</span>
      </div>
      <img className="p-gameSplash_logo" alt="spolive" src={img_logo} />
    </Wrapper>
  );
};

const Wrapper = styled.div<{ bgimg: string; hasGameDate: boolean }>`
  position: relative;
  width: ${viewportSize}px;
  height: ${viewportSize * 0.525}px;
  margin: 0 auto;
  padding: 66px 14px ${({ hasGameDate }) => (hasGameDate ? "30px" : "66px")};
  box-sizing: border-box;
  background-image: url(${({ bgimg }) => bgimg});
  background-size: cover;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: flex-end;
  .p-gameSplash {
    &_labelArea {
      position: absolute;
      top: 60px;
    }
    &_bgFrame {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url(${img_bgFrame});
      background-color: rgba(0, 0, 0, 0.5);
    }
    &_teamPointArea {
      position: relative;
      display: flex;
      justify-content: center;
      margin: 80px auto 20px;
      width: 100%;
    }
    &_pointArea {
      display: flex;
      flex-direction: row;
      justify-content: center;
      font-size: 128px;
      font-weight: bold;
      width: 400px;
      height: 180px;
      text-align: center;
      span {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      [data-threedigit="true"] {
        font-size: 100px;
      }
    }
    &_pointVs {
      font-size: 128px;
      font-weight: bold;
      width: 200px;
      height: 180px;
    }
    &_pointNumber {
      flex: 1;
      font-family: "Oswald", sans-serif;
    }
    &_pointHyphen {
      flex: 0;
      width: 60px;
    }
    &_teamArea {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 1;
    }
    &_teamImgWrapper {
      display: block;
      background-color: #fff;
      width: 180px;
      height: 180px;
      border-radius: 50%;
      margin-bottom: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
    }
    &_teamName {
      width: 400px;
      overflow: hidden;
      font-size: 34px;
      text-overflow: ellipsis;
      font-weight: bold;
      white-space: nowrap;
    }
    &_leagueText {
      position: relative;
      font-size: 32px;
      font-weight: bold;
    }
    &_leagueDateTime {
      position: relative;
      font-family: "Oswald", sans-serif;
    }
    &_leagueDate {
      font-size: 48px;
      font-weight: bold;
    }
    &_leagueDayOfWeek {
      font-size: 32px;
      font-weight: bold;
    }
    &_leagueTime {
      padding-left: 16px;
      font-size: 48px;
      font-weight: bold;
    }
    &_confetti {
      position: absolute;
      width: 428px;
      height: 578px;
      top: 30px;
      left: 40px;
      &[data-ishomewin="false"] {
        left: 710px;
      }
    }
    &_crown {
      position: absolute;
      width: 80px;
      height: 80px;
      top: -73px;
      left: 165px;
      &[data-ishomewin="false"] {
        left: 934px;
      }
    }
    &_logo {
      position: absolute;
      width: 170px;
      height: 48px;
      top: 50px;
      left: 70px;
    }
  }
`;
