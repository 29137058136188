import { getPeriodNames } from "assets/sportsData";
import React, { useContext, useMemo } from "react";
import { GameDataContext } from "../hooks/gameData";
import styled from "styled-components";
import { getPeriodNameByGame } from "libs/gameTimeTextGenerator";

export const ScoreBoard = () => {
  const {
    latestGamePoints,
    sportsId,
    gameData,
    firstHalf,
    lastHalf: secondHalf
  } = useContext(GameDataContext);

  const currentPeriod = useMemo(() => {
    return gameData && getPeriodNameByGame(sportsId, gameData);
  }, [gameData, sportsId]);

  const isPeriodGame = useMemo(() => {
    return gameData?.periodList && gameData.periodList.length > 0;
  }, [gameData?.periodList]);
  const fontSize = useMemo(() => {
    // 対戦形式はfont80に。ピリオド形式はアメフト等でコメントがかぶるので60にする
    return isPeriodGame ? "60px" : "80px";
  }, [isPeriodGame]);

  const mb = useMemo(() => {
    //対戦形式は余白があるので下に余白を追加する
    // アメフトで追加するとOTがコメント欄にかぶるのでmargin-bottom は低めにする
    return isPeriodGame ? "8px" : "24px";
  }, [isPeriodGame]);

  const mt = useMemo(() => {
    return isPeriodGame ? "0px" : "36px";
  }, [isPeriodGame]);

  const { periodScoreList, allPeriods } = useMemo(() => {
    const periodNames = sportsId && getPeriodNames(sportsId);
    if (!periodNames) return { periodScoreList: [], allPeriods: [] };
    const formattedPeriodNames = Object.keys(periodNames)
      .map(key => periodNames[key])
      .sort((a, b) => a.order - b.order);

    const filteredPeriodNames = formattedPeriodNames.filter(periodName => periodName.nameScore);

    const periodScoreList = filteredPeriodNames.map(periodName => {
      const gamePoint = latestGamePoints?.[periodName.id];
      return { periodName, gamePoint };
    });
    const allPeriods = formattedPeriodNames.map(periodName => {
      const gamePoint = latestGamePoints?.[periodName.id];
      return { periodName, gamePoint };
    });
    return {
      periodScoreList: periodScoreList,
      allPeriods: allPeriods
    };
  }, [latestGamePoints, sportsId]);

  const currentPeriodIndex = useMemo(() => {
    return allPeriods.findIndex(item => item.periodName.id === currentPeriod?.id);
  }, [currentPeriod?.id, allPeriods]);

  const startIsFirst = useMemo(() => {
    return gameData?.time_firstHalfStartTime ? true : false;
  }, [gameData?.time_firstHalfStartTime]);

  const startIsSecond = useMemo(() => {
    return gameData?.time_secondHalfStartTime ? true : false;
  }, [gameData?.time_secondHalfStartTime]);

  const isGameEnd = useMemo(() => {
    return gameData?.time_isGameEnd ? true : false;
  }, [gameData?.time_isGameEnd]);

  const makeGameScore = (id: string, isHome: boolean) => {
    if (id === "firstHalf") {
      if (isHome) {
        if (startIsFirst && firstHalf.home === "-") {
          return 0;
        } else {
          return firstHalf.home;
        }
      } else {
        if (startIsFirst && firstHalf.away === "-") {
          return 0;
        } else {
          return firstHalf.away;
        }
      }
    }

    if (id === "secondHalf") {
      if (isHome) {
        if (startIsSecond && secondHalf.home === "-") {
          return 0;
        } else {
          return secondHalf.home;
        }
      } else {
        if (startIsSecond && secondHalf.away === "-") {
          return 0;
        } else {
          return secondHalf.away;
        }
      }
    }
    return "-";
  };
  const makeVsGameScore = () => {
    return (
      <>
        {periodScoreList.map((item, i) => (
          <div key={item.periodName.id} className="p-score-scoreboard_item">
            <div className="p-score-scoreboard_vs_point">
              {makeGameScore(item.periodName.id, true)}
            </div>
            <div className="p-score-scoreboard_period">{item.periodName.nameScore}</div>
            <div className="p-score-scoreboard_vs_point">
              {makeGameScore(item.periodName.id, false)}
            </div>
          </div>
        ))}
      </>
    );
  };
  const makePeriodGameScore = () => {
    return (
      <>
        {allPeriods.map((item, i) => {
          if (item.periodName?.isBreak) {
            return null;
          }
          return (
            <div className="p-score-scoreboard_item">
              <div className="p-score-scoreboard_point">
                {typeof item.gamePoint?.home === "number"
                  ? item.gamePoint?.home
                  : i <= currentPeriodIndex || isGameEnd
                  ? 0
                  : "-"}
              </div>
              <div className="p-score-scoreboard_period">{item.periodName.nameScore}</div>
              <div className="p-score-scoreboard_point">
                {typeof item.gamePoint?.away === "number"
                  ? item.gamePoint?.away
                  : i <= currentPeriodIndex || isGameEnd
                  ? 0
                  : "-"}
              </div>
            </div>
          );
        })}
      </>
    );
  };

  return (
    <ScoreBoardWrapper className="p-score-scoreboard" fontSize={fontSize} mb={mb} mt={mt}>
      {gameData?.periodList && gameData.periodList.length > 0
        ? makePeriodGameScore()
        : makeVsGameScore()}
    </ScoreBoardWrapper>
  );
};

const ScoreBoardWrapper = styled.div<{
  fontSize: string;
  mb: string;
  mt: string;
}>`
  margin-top: ${({ mt }) => mt};
  .p-score-scoreboard {
    font-size: ${({ fontSize }) => fontSize};
  }
  .p-score-scoreboard_item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 360px;
    margin-bottom: ${({ mb }) => mb};
  }
  .p-score-scoreboard_point {
    font-size: ${({ fontSize }) => fontSize};
    width: 100px;
    text-align: center;
    line-height: 1;
  }

  .p-score-scoreboard_vs_point {
    font-size: ${({ fontSize }) => fontSize};
    width: 100px;
    text-align: center;
    line-height: 1;
  }

  .p-score-scoreboard_period {
    line-height: 1;
    font-size: ${({ fontSize }) => fontSize};
    color: #bebebe;
    width: 480px;
    text-align: center;
  }
`;
