import { useContext, useEffect, useRef, useState } from "react";

import { League } from "models/league";
import { Game } from "models/game";
import { PeriodGamePoint } from "models/timeline";

import { calculateTimelineData } from "assets/js/calculateTimelineData";
import { FirebaseContext } from "contexts";
import { getTeamDataAndPlayer } from "assets/js/firebase";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
dayjs.extend(timezone);
dayjs.extend(utc);

const useGameData = ({
  sportsId,
  leagueId,
  gameId,
  timelineId,
  isGetPlayersData = false
}: {
  sportsId: string;
  leagueId: string;
  gameId: string;
  timelineId?: string;
  isGetPlayersData?: boolean;
}) => {
  const [gameData, setGameData] = useState<Game>();
  const [leagueData, setLeagueData] = useState<League>();
  const [timelineData, setTimelineData] = useState<{}>();
  // TODO: TimelinePlayのネストを解消したら型を付ける
  const [specifiedPlay, setSpecifiedPlay] = useState<any>();
  const [latestPoints, setLatestPoints] = useState<{
    home: number;
    away: number;
  }>({ home: 0, away: 0 });
  const [latestGamePoints, setLatestGamePoints] = useState<PeriodGamePoint>();
  const [placeImageUrl, setPlaceImageUrl] = useState<string>("");

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error>();

  const firebaseRef = useRef(useContext(FirebaseContext));

  useEffect(() => {
    const { firestore, realtimeDb } = firebaseRef.current;
    if (!firestore) throw new Error("Firestore is not initialized");
    if (!realtimeDb) throw new Error("Realtime DB is not initialized");

    const load = async () => {
      setLoading(true);
      try {
        const gameDataDoc = await firestore
          .collection(`sportsData/${sportsId}/league/${leagueId}/games/`)
          .doc(gameId)
          .get();
        const gameData = gameDataDoc.data() as Game;
        if (gameData.placeId) {
          await firestore
            .collection("system/common/places")
            .doc(gameData.placeId)
            .get()
            .then(placeDataDoc => {
              const placeData = placeDataDoc.data();
              if (placeData?.sportsImgUrl?.[sportsId]) {
                setPlaceImageUrl(placeData.sportsImgUrl[sportsId]);
              } else {
                if (placeData?.defaultImgUrl) {
                  setPlaceImageUrl(placeData.defaultImgUrl);
                }
              }
            });
        }

        const leagueDoc = await firestore
          .collection(`sportsData/${sportsId}/league/`)
          .doc(leagueId)
          .get();
        const leagueData = leagueDoc.data() as League;
        setLeagueData(leagueData);

        if (gameData.team_awayId) {
          gameData.awayTeam = await getTeamDataAndPlayer({
            firestore,
            sportsId,
            leagueId,
            isMatchIndividual: Boolean(leagueData.isMatchIndividual),
            teamId: gameData.team_awayId
          });
        }
        if (gameData.team_homeId) {
          gameData.homeTeam = await getTeamDataAndPlayer({
            firestore,
            sportsId,
            leagueId,
            isMatchIndividual: Boolean(leagueData.isMatchIndividual),
            teamId: gameData.team_homeId
          });
        }
        setGameData({ ...gameData, id: gameDataDoc.id });

        await new Promise<void>(resolve => {
          realtimeDb
            .ref(`timeline/${sportsId}/${leagueId}/${gameId}`)
            .on("value", timelineSnapShot => {
              const { timelineData, latestPoints, latestGamePoints, specifiedPlay } =
                calculateTimelineData(timelineSnapShot.val(), sportsId, timelineId);
              if (timelineData) {
                setTimelineData(timelineData);
              }
              if (specifiedPlay) {
                setSpecifiedPlay(specifiedPlay);
              }
              setLatestPoints(latestPoints);
              setLatestGamePoints(latestGamePoints);
              resolve();
            });
        });
        setError(undefined);
      } catch (err) {
        if (err instanceof Error) {
          setError(err);
        } else {
          console.error("初期データ取得時にエラー発生", err);
        }
      }
      setLoading(false);
    };

    load();
  }, [sportsId, leagueId, gameId, timelineId, isGetPlayersData]);

  dayjs.tz.setDefault("Asia/Tokyo"); //タイムゾーンはひとまず日本を指定
  const infoDate = gameData?.info_datetime?.seconds
    ? dayjs(gameData?.info_datetime?.seconds * 1000)
    : undefined;
  const infoDateDay = infoDate ? infoDate.tz().format("YYYY.M.D") : "";
  const infoDateDayOfWeek = infoDate ? infoDate.tz().format("(ddd)") : "";
  const infoDateTime = infoDate ? infoDate.tz().format("H:mm~") : "";
  const infoDateUtc = infoDate ? infoDate.tz().format("UTCZ") : "";

  return {
    gameData,
    leagueData,
    timelineData,
    specifiedPlay,
    latestPoints,
    latestGamePoints,
    placeImageUrl,
    loading,
    error,
    infoDateDay,
    infoDateDayOfWeek,
    infoDateTime,
    infoDateUtc
  };
};

export default useGameData;
