import moment from "moment";
import { Game } from "models/game";
import dayjs from "dayjs";

export const convertGameStatus = (isJa: boolean, gameData: Game | undefined) => {
  if (!gameData) {
    return { status: "hidden", label: "none", text: "", isShowPoint: false };
  }
  const {
    info_datetime,
    isOnlyResult,
    period_currentStatusId,
    time_firstHalfStartTime,
    time_isGameEnd,
    time_isGameCancelled,
    time_isGamePostponed,
    periodList
  } = gameData;

  //試合中止・延期の判定は、最優先で行う
  if (time_isGameCancelled) {
    return {
      status: "cancelled",
      label: "white",
      text: isJa ? "試合中止" : "Cancelled",
      isShowPoint: false
    };
  }
  if (time_isGamePostponed) {
    return {
      status: "postponed",
      label: "white",
      text: isJa ? "試合延期" : "Postponed",
      isShowPoint: false
    };
  }

  //試合後の場合
  if (time_isGameEnd) {
    return {
      status: "finished",
      label: "white",
      text: isJa ? "試合終了" : "Finished",
      isShowPoint: true
    };
  }

  if (!info_datetime) {
    return { status: "hidden", label: "none", text: "", isShowPoint: false };
  }

  const hasGamePeriod = Boolean(periodList?.length);
  const isStartedGameWithPeriod =
    hasGamePeriod && Boolean(periodList?.filter(p => Boolean(p.startedAt)).length);

  //試合中なら開始時間(info_datetime)より48時間以内はライブにする
  const nowTime = dayjs();
  const liveLimit = dayjs((info_datetime?.seconds + 48 * 3600) * 1000);
  const isBeforeLiveLimitTime = nowTime.isBefore(liveLimit);
  if (isBeforeLiveLimitTime && (time_firstHalfStartTime || isStartedGameWithPeriod)) {
    //結果のみの場合
    if (isOnlyResult) {
      return {
        status: "during",
        label: "red",
        text: isJa ? "試合中" : "Live",
        isShowPoint: true
      };
    }
    return {
      status: "live",
      label: "red",
      text: isJa ? "試合中" : "Live",
      isShowPoint: true
    };
  }
  const gameDate = moment.unix(info_datetime.seconds).format("YYYY:MM:DD");
  const today = moment().format("YYYY:MM:DD");
  const isTodayGame = gameDate === today;
  //当日以外の未来・過去の試合は時間を出さない
  if (!isTodayGame) {
    return { status: "hidden", label: "none", text: "", isShowPoint: false };
  }
  const currentPeriod = gameData?.periodList?.filter(p => p.id === period_currentStatusId)[0];

  //試合前の場合
  if (
    (!time_firstHalfStartTime && !period_currentStatusId) ||
    (period_currentStatusId && !currentPeriod?.startedAt)
  ) {
    return {
      status: "today",
      label: "green",
      text: isJa ? "本日開催" : "Today",
      isShowPoint: false
    };
  }

  //結果のみの場合
  if (isOnlyResult) {
    return {
      status: "during",
      label: "red",
      text: isJa ? "試合中" : "Live",
      isShowPoint: true
    };
  }

  return {
    status: "live",
    label: "red",
    text: isJa ? "試合中" : "Live",
    isShowPoint: true
  };
};
