import dayjs from "dayjs";
import { Game, getPeriodById, Period } from "models/game";
import { periodNames, getPeriodNames } from "assets/sportsData";
import { SportsEnum } from "../models/sports";
import { Timestamp } from "@firebase/firestore-types";
import { PeriodDic } from "models/sportsDic";

export type GameStatus = "hidden" | "finished" | "live" | "active";
export type PointStatus = "versus" | "point";
export type GameLabel =
  | "Others.cancelledGame"
  | "Others.postponedGame"
  | "Others.finishedGame"
  | "Others.todayGame"
  | "Others.duringGame"
  | "Game.breakingNewsOnly"
  | "Others.liveGame"
  | "Others.liveStreaming"
  | "Others.beforeGame"
  | "Game.hasMovie"
  | "Others.playingVideo";

const LIMIT_OF_START_GAME = -7;

const getGameBaseTime = (stopGameTimestamp?: Timestamp) => {
  return stopGameTimestamp ? dayjs(stopGameTimestamp.toDate()) : dayjs();
};
const getGameTimeMin = ({
  diffTimeSec,
  baseTime,
  maxTimeMin,
  stopGameTimestamp
}: {
  maxTimeMin: number;
  diffTimeSec: number;
  baseTime: dayjs.Dayjs;
  stopGameTimestamp?: Timestamp;
}): number => {
  const nowTime = getGameBaseTime(stopGameTimestamp);
  const gameMin = nowTime.add(-1 * diffTimeSec, "second").diff(baseTime, "minute");
  if (gameMin > maxTimeMin) {
    return maxTimeMin;
  }
  if (gameMin < 0) {
    return 0;
  }
  return gameMin;
};

const getGameTimeSec = ({
  diffTimeSec,
  baseTime,
  stopGameTimestamp
}: {
  diffTimeSec: number;
  baseTime: dayjs.Dayjs;
  stopGameTimestamp?: Timestamp;
}) => {
  const nowTime = getGameBaseTime(stopGameTimestamp);
  const gameSec = nowTime.add(-1 * diffTimeSec, "second").diff(baseTime, "second");
  return gameSec % 60;
};

export const getPeriodTime = (
  sportsId: string,
  gameData: Game,
  isCountDown: boolean = false
): { sec: number; min: number; period: Period } | undefined => {
  if (!gameData.periodList || !gameData?.period_currentStatusId) return undefined;
  const currentPeriod = getPeriodById(gameData.periodList, gameData?.period_currentStatusId);
  if (!currentPeriod?.startedAt) return undefined;
  // ピリオドが終了したタイミングで時刻を空にしておく（次のピリオドが始まったときに画面に一瞬前のピリオドの時刻が表示されるため）
  if (currentPeriod && currentPeriod.finishedAt) {
    return {
      sec: 0,
      min: 0,
      period: currentPeriod
    };
  }
  const baseTime = dayjs.unix(currentPeriod.startedAt.seconds);
  const diffTimeSec = currentPeriod.diffTimeSec || 0;

  const periodDic = periodNames(sportsId)[gameData?.period_currentStatusId];
  let maxTimeMin: undefined | number;
  if (periodDic?.isExtra) {
    maxTimeMin = gameData?.periodOT ?? periodDic?.displayMaxTimeMin;
  } else {
    maxTimeMin = gameData?.periodTime ?? periodDic?.displayMaxTimeMin;
  }
  // 0は許容する
  if (maxTimeMin === undefined) return undefined;

  const gameTimeMin = getGameTimeMin({ baseTime, diffTimeSec, maxTimeMin });
  let gameTimeSec = getGameTimeSec({ baseTime, diffTimeSec });
  if (gameTimeMin === maxTimeMin) {
    gameTimeSec = 0;
  }
  if (isCountDown) {
    return {
      sec: (60 - gameTimeSec) % 60,
      min: maxTimeMin - gameTimeMin - (gameTimeSec !== 0 ? 1 : 0),
      period: currentPeriod
    };
  } else {
    return {
      sec: gameTimeSec,
      min: gameTimeMin,
      period: currentPeriod
    };
  }
};

export const isStartFirstHalfSecondHalfGame = (gameData: Game) => {
  return !!gameData?.time_firstHalfStartTime;
};

export const isNotGameStartDate = (gameData: Game) => {
  const {
    time_firstHalfStartTime,
    time_isGameEnd,
    time_isGameCancelled,
    time_isGamePostponed,
    info_datetime
  } = gameData;
  if (!info_datetime) return false;
  const gameDate = dayjs.unix(info_datetime.seconds).format("YYYY:MM:DD");

  const isGameStartDate =
    dayjs().add(LIMIT_OF_START_GAME, "day").format("YYYY:MM:DD") <= gameDate &&
    gameDate <= dayjs().format("YYYY:MM:DD");

  return (
    time_isGameCancelled ||
    time_isGamePostponed ||
    time_isGameEnd ||
    !isGameStartDate ||
    !time_firstHalfStartTime
  );
};

export const zeroPadding = (num: number, length: number) => {
  return ("0000000000" + num).slice(-length);
};

export const getPeriodNameByGame = (sportsId: string, gameData: Game) => {
  const periodNames = getPeriodNames(sportsId);
  if (!gameData.periodList || !gameData.period_currentStatusId) {
    //試合区切りの入っていない前半後半形式の試合用。ラグビー、サッカー対応。フライングディスク等の前半後半形式は途対応が必要。
    switch (sportsId) {
      case SportsEnum.soccer:
      case SportsEnum.rugby:
        if (gameData?.time_firstHalfStartTime && !gameData?.time_isFirstHalfEnd) {
          return periodNames?.["firstHalf"];
        }
        if (
          gameData?.time_firstHalfStartTime &&
          gameData?.time_isFirstHalfEnd &&
          !gameData?.time_secondHalfStartTime
        ) {
          return periodNames?.["halfTime"];
        }
        if (
          gameData?.time_firstHalfStartTime &&
          gameData?.time_isFirstHalfEnd &&
          gameData?.time_secondHalfStartTime &&
          !gameData?.time_isGameEnd
        ) {
          return periodNames?.["secondHalf"];
        }
        return undefined;
      default:
        return undefined;
    }
  }
  const period = getPeriodById(gameData.periodList, gameData.period_currentStatusId);
  if (!period || !periodNames) return undefined;
  return periodNames?.[period.id];
};

export const getPeriodNameOfTime = (period: PeriodDic | undefined, lang: string) => {
  //ハーフタイムのスコア用の設定をもっていないので決め打ち
  if (period?.id === "halfTime") {
    return "HT";
  }

  switch (lang) {
    case "ja":
      return period?.nameScore;
    case "en":
      return period?.nameScore_en;
    default:
      return period?.nameScore_en;
  }
};
