import { last } from "lodash";
import { Game } from "models/game";
import { isShowGamePeriodAfterEndGame, periodNames } from "assets/sportsData";
import { SportsEnum } from "models/sports";
import { getInning } from "assets/sportsData/baseball";

export const getGameTimeTextForPeriod = (
  gameData: Game | undefined,
  sportsId: string,
  isJa: boolean
): string | null => {
  if (!gameData) {
    return null;
  }
  if (sportsId === SportsEnum.baseball) {
    return getBaseballLabelText(gameData);
  }
  const currentPeriodId = gameData?.period_currentStatusId;

  // 試合が終わっている場合かつ
  // isShowGamePeriodAfterEndGameがtrueの場合(テニスなど)
  // には最後のperiod名を表示
  if (gameData?.time_isGameEnd && isShowGamePeriodAfterEndGame(sportsId) && gameData?.periodList) {
    const lastPeriod = last(gameData.periodList);
    if (!lastPeriod?.id) {
      return null;
    }
    const { name: periodName, name_en: periodName_en } = periodNames(sportsId)?.[lastPeriod.id];
    return isJa ? periodName : periodName_en;
  }

  const current = gameData?.periodList?.filter(p => p.id === currentPeriodId)[0];
  if (
    !currentPeriodId ||
    !current?.startedAt ||
    !sportsId ||
    !current.id ||
    !periodNames(sportsId)?.[current.id]
  ) {
    // 試合開始していない or currentPeriodIdとデータが不整合
    return null;
  }

  const { name: periodNameJa, name_en: periodName_en } = periodNames(sportsId)[current.id];

  const periodName = isJa ? periodNameJa : periodName_en;
  return periodName;
};

export const getBaseballLabelText = (gameData: Game) => {
  const inning = getInning(gameData);
  if (!inning) return "";
  return inning.period?.name + (inning.isTop ? "表" : "裏");
};
