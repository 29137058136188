import React, { useContext } from "react";
import { ScoreBoard } from "./ScoreBoard";
import styled from "styled-components";
import { GameDataContext } from "../hooks/gameData";

export const TimeScore = ({
  period = "",
  time,
  isDisplayTime = true
}: {
  period?: string;
  time: string;
  isDisplayTime?: boolean;
}) => {
  const { sportsId } = useContext(GameDataContext);
  return (
    <TimeScoreWrapper className="p-score-timeScore">
      <div className="p-score-timeScore_time-board">
        <p
          className={
            "p-score-timeScore_period " + (!isDisplayTime ? "p-score-timeScore_period--large" : "")
          }
        >
          {period}
        </p>
        {isDisplayTime && <p className="p-score-timeScore_time">{time}</p>}
        {sportsId !== "basketball3x3" && <ScoreBoard />}
      </div>
    </TimeScoreWrapper>
  );
};

const TimeScoreWrapper = styled.div`
  width: 100%;
  z-index: 10;
  .p-score-timeScore {
    &_time-board {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &_period {
      font-size: 60px;
      line-height: 1;
      margin-bottom: 24px;
      margin-top: 64px;
      &--large {
        font-size: 120px;
      }
    }
    &_time {
      margin-bottom: 32px;
      line-height: 1;
      font-size: 120px;
    }
    &_point-wrapper-wrapper {
      width: 100%;
      justify-content: center;
    }
  }
`;
